<template>
   <div id="search_toolbar" :class="$vuetify.breakpoint">
    <v-card v-if="$vuetify.breakpoint.smAndDown" rounded="xl" min-height="38px">
          
        <v-list two-line v-if="expanded" id="settings-list">
          <v-list-item>
            <v-select  
                v-model="$parent.$parent.pos_selected"
                hide-details
                :menu-props="{ offsetY: true }"
                :aria-label="$t('options.pos')"
                :items="pos_items"
                append-icon="expand_more"
                @input="updatePos">
            </v-select>
          </v-list-item>
            <v-list-item>
            <v-checkbox
                v-model="fulltext"
                hide-details
                :label="$t('options.fulltext')"/>
            </v-list-item>

          <v-list-item>
            <v-checkbox  
                v-model="inflected_results"
                hide-details=""
                :label="$t('options.inflected')"/>
                </v-list-item>

          <v-list-item v-if="($route.name == 'search' || $route.name == 'word') && $store.state.collapseArticles != 'auto'">
              <v-switch hide-details dense v-model="updateCollapse" :label="$t('options.collapse')"></v-switch>
          </v-list-item>
        </v-list>
        <v-btn v-if="$vuetify.breakpoint.smAndDown"
          class="search_options"
          v-bind:class="{'expanded': expanded}"
          height="38px"
          rounded
           text
           @click="expanded=!expanded"
           :aria-expanded="expanded ? 'true' : 'false'" 
           aria-controls="settings-list">
           {{expanded? '' : $t('options.title')}}<v-icon small color="primary" :right="!expanded">{{expanded ? 'expand_less': 'expand_more'}}</v-icon></v-btn>
        </v-card>
<div id="desktop_toolbar" v-else>
          <span>
            <v-checkbox  
                v-model="inflected_results"
                dense
                hide-details
                :label="$t('options.inflected')"/>
        </span>
        <span>
        <v-checkbox 
                    v-model="fulltext"
                    dense
                    hide-details
                    :label="$t('options.fulltext')"/>
        </span>
        <span id="toolbar-pos-select">

             <v-select  
                v-model="$parent.$parent.pos_selected"
                hide-details
                :menu-props="{ offsetY: true }"
                dense
                :aria-label="$t('options.pos')"
                :items="pos_items"
                append-icon="expand_more"
                @input="updatePos">
            </v-select>
        </span>
        <span id="display-options" v-if="($route.name == 'search' || $route.name == 'word') && $store.state.collapseArticles != 'auto'">
        <v-switch  v-model="updateCollapse" hide-details dense :label="$t('options.collapse')"></v-switch>
        </span>
</div>
   </div>
</template>

<script>

export default {
    name: "SearchToolbar",
    data: function() {
      return {
        expanded: false
      }
    },
    computed: {
        updateCollapse: {
            get () { return this.$store.state.collapseArticles != "never"}, 
            set(value) {
                if (value) this.$store.commit("setCollapse", "always")
                else this.$store.commit("setCollapse", "never")
            }
        },
      inflected_results: {
        get() {
          return this.$parent.$parent.scope.includes("i")
        },
        set() {
          if (this.$parent.$parent.scope.includes("i")) {
            this.$emit('updateScope', this.$parent.$parent.scope.replace("i",""))
          }
          else {
            this.$emit('updateScope', this.$parent.$parent.scope + "i")
          }
        }
      },
      fulltext: {
        get() {
          return this.$parent.$parent.scope.includes("f")
        },
        set() {
          if (this.$parent.$parent.scope.includes("f")) {
            this.$emit('updateScope', this.$parent.$parent.scope.replace("f",""))
          }
          else {
            this.$emit('updateScope', this.$parent.$parent.scope + "f")
          }
        }
      },
      pos_items: function() {
        let items =  ["VERB", "NOUN", "ADJ", "PRON", "DET", "ADV", "ADP", "CCONJ", "SCONJ", "INTJ"].map(tag =>{
          return {text: this.$t("pos_tags_plural." + tag ), value: tag}
        })

        items.unshift({text: this.$t('all_pos'), value: null})
        return items
      
      }
    },
    methods: {
      reset () {
        this.$emit('updatePos', "ALL")
        this.$emit('updateScope', "ei")
      },
      updatePos (pos) {
        this.$emit('updatePos', pos)
      }
  }
}
</script>

<style scoped>

.v-card {
  padding-top: 0px !important;
  padding-bottom: 24px !important;
  padding-top: 10px;
  margin-top: 10px;
  min-height: 36px;
}

.v-list-item {
  padding-left: 24px;
  padding-right: 24px;
}

.search_options {
    position: absolute;
    min-height: 36px;
    bottom: 0px;
    left: 0px;
    border-radius: 28px;
    float: left;
    width: 100%;
}

.search_options.expanded {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  min-height: 24px;
}


#desktop_toolbar {
  display: flex;
  padding-left: 10px;
  margin-right: 10px;
  gap: 24px;
}

#search_toolbar {
  margin-bottom: 10px;
}

.md#search_toolbar, .lg#search_toolbar, .xl#search_toolbar {
  padding-top: 10px;
}

#desktop_toolbar v-combobox {
  width: 300px;
}

#toolbar-pos-select {
  max-width: 200px;
}

.v-input {
  margin-top: 0px;
}
</style>

