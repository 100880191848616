<template>
  <div :class="$vuetify.breakpoint.name">
    <form @submit.prevent="submit">

    <v-combobox
        v-model="select"
        prepend-inner-icon="search"
        autocapitalize = 'off'
        :loading="loading"
        :items="items"
        append-icon=""
        :search-input.sync="search"
        item-text="match"
        :menu-props="{maxHeight: $vuetify.breakpoint.name === 'xs' ? 190 : 500, transition: 'fade-transition', allowOverflow: true}"
        return-object
        rounded
        clearable
        :autofocus="!$route.hash && !$store.state.noMouse && $vuetify.breakpoint.mdAndUp"
        hide-no-data
        no-filter
        auto-select-first
        hide-details
        :label="$t('search_placeholder') + $t(`dicts.${$parent.lang}`)"
        :aria-label="$t('search_placeholder') + $t(`dicts.${$parent.lang}`)"
        solo
        full-width
        :placeholder="$t('search_placeholder') + $t(`dicts.${$parent.lang}`)"
        ref="autocomplete"
        color="primary"
        :dense="$vuetify.breakpoint.mdAndDown"
      >
      <template v-slot:prepend-inner>
        <v-menu allowOverflow offsetY v-model="dictMenuOpened">
          
        <template v-slot:activator="{ on, attrs }">
       <v-btn  v-bind="attrs"
               v-on="on"
               plain
               depressed 
               color = "primary" 
               text 
               @click.native="items=[]">
               
          <v-icon left>book</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">
          {{$t(`dicts.${$parent.lang}`)}}
          <v-icon right>{{ dictMenuOpened? 'expand_less' : 'expand_more'}}</v-icon>
          </span><span v-if="$vuetify.breakpoint.smAndDown">{{$t(`dicts_short.${$parent.lang}`)}}</span></v-btn>
                </template>
        <v-list>
          <v-list-item  v-for="item in ['bm,nn','bm','nn'].map(l => {return {label: $t(`dicts.${l}`), tag: l}})" :key="item.tag"
          active-class="v-list-item--active" @click="update_lang_form(item.tag)">
                <v-list-item-icon v-if="$parent.lang == item.tag"><v-icon color="primary">radio_button_checked</v-icon></v-list-item-icon>
                <v-list-item-icon v-else><v-icon>radio_button_unchecked</v-icon></v-list-item-icon>
                <v-list-item-title >{{ item.label }}</v-list-item-title>
          </v-list-item>

        </v-list>

        </v-menu>
        <v-divider vertical aria-hidden="true"/><v-icon right>search</v-icon>

      </template>

      <template v-slot:item="data">
        
      <span v-if="data.item.type == 'empty' || data.item.type == 0">
          Søk: <strong>{{data.item.match}}</strong> </span>

       <span v-if="data.item.type == 'exact'">
               <span class="searchLemma">
       <strong>{{data.item.match}}</strong>
      </span>
        <span class="dict-parentheses" v-if="(get_lang()=='bm,nn')">
             ({{["bokmål","nynorsk","bokmål, nynorsk"][data.item.lang-1]}})
        </span>
       </span>      
          <span v-if="data.item.type == 'other'">
          {{data.item.match}} </span>

      </template>
    </v-combobox>

    <SearchToolbar @updatePos="update_pos"
                     @updateScope="update_scope" />


  </form>
  </div>
</template>

<script>
import SearchToolbar from './SearchToolbar.vue'

  export default {
    data: function() {
      return {
        loading: false,
        items: [],
        search: null,
        select: this.$parent.queryString || null,
        suggesting: null,
        menuDialog: false,
        dictMenuOpened: false
      }
    },
    components: {
      SearchToolbar
    },
    watch: {
      search (val) {
        const time = Date.now()
        if (! val) {
          this.items = []
        } else {
          this.run_query(val, time)
        }
      },
      select(item) {
        if (item) {
          if (typeof item != 'string') {
          this.items = []
          this.suggesting = false
          

          this.submit(item)
          }
        }
      },
      $route (to, from) {
        this.select = to.query.q || ""
      }
    },
    methods: {
      hotkey(e) {
        if (e.key !== "/") return;
        if (this.$store.state.disableHotkey) return
        if (e.altKey || e.ctrlKey || e.metaKey) return;
        if (/^(?:input|textarea|select)$/i.test(e.target.tagName)) return;
        e.preventDefault()
        this.$refs.autocomplete.$refs.input.focus()
      },
      
      update_lang_form(lang) {
        this.$emit('update-lang-form', lang)
        // Submit if switching on front page
         if (this.search && this.search!=this.$parent.queryString && !this.items[0]) {
          this.submit({q: this.search, match: this.search, time: Date.now()})
        }
        else {
          this.$parent.reload_params()
        }
       
      },
      update_scope(scope) {
        this.$emit('updateScope', scope)
      },
        update_pos(pos) {
        this.$emit('updatePos', pos)
      },
      get_lang() {
        return this.$parent.lang
      },
      run_query(q, time) {
        q = q.trim()

        if (this.items[0]) {
          if (this.items[0].time < time) {
            // Whitespace necessary in case option already exists in dropdown
            this.items.splice(0,1, {q, match: q, time, type: 0})
          }
        }
        else {
          this.items.push({q, match: q, time, type: 0})
        }
        let words = q.split(/ |\|/)
        if (words.length > 20) {
          this.items = [{q, match: q, time, type: 0}]
          return
        }
        for (let i = 0; i < words.length; i++) {
          if (words[i].length > 40) {
            this.items = [{q, match: q, time, type: 0}]
            return
          }
        }

          this.suggesting = true
    
          let self = this
          let params = {q, dict: self.get_lang(), n: 20, dform: 'int', meta: 'n', include: this.$parent.scope, wc: self.$parent.pos_selected}
          this.$parent.api.get('api/suggest?', {params})
            .then(async (response) => {
                        if (self.$refs.autocomplete.searchInput == q & self.suggesting) {
                          let suggestions = []
                          if (response.data.a.exact) {
                            suggestions = response.data.a.exact.map(item => ({q: q, match: item[0], time: time, lang: [item[1]], type: "exact"}))
                          }
                          if (response.data.a.inflect) {
                            let inf = response.data.a.inflect.map(item => ({q: q, match: q, inflected: item[0], time: time, type: "other"}))
                            suggestions = suggestions.concat(inf)
                          }
                          if (response.data.a.freetext) {
                            let fr = response.data.a.freetext.map(item => ({q: q, match: item[0], time: time, type: "other"}))
                            suggestions = suggestions.concat(fr)
                          }
                          


                          if (suggestions.length) {
                            if (suggestions[0].q.toLowerCase() != suggestions[0].match.toLowerCase()) {
                              suggestions.unshift({q, match: q, time, type: "empty"})
                            }
                            self.items = suggestions
                          }
                          else {
                            self.items = [{q, match: q, time, type: "empty"}]
                          }
                        }
                    })

        


      },
      submit(item) {
        if (!item.q) {
          item = this.items[0] || {q: this.search, match: this.search, time: Date.now() }
          this.items = []
        }

          this.$emit('submit', item)
          let self = this

          setTimeout(() => {
          if (!self.$store.state.noMouse && this.$vuetify.breakpoint.mdAndUp) self.$refs.autocomplete.$refs.input.select()
          else self.$refs.autocomplete.$refs.input.blur()
          self.suggesting = false
          }, 1)

      }
    },
    mounted: function() { 
      document.addEventListener("keydown", this.hotkey);
    }
  }
</script>


<style scoped>

  .searchResult {
    margin-right: 5px;
  }

  .searchLemma {
    color: var(--v-primary-base);
  }

  form {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }

  .accessibility_link {
  display: inline;
  position: absolute;
  left: -10000px;
  }

  .accessibility_link:focus {
    position: absolute;
    left: 48px;
    top : 48px;
    background-color: white;
    padding: 10px;
    z-index: 10000;
  }



  
</style>
