<template>
  <li class="compound_list">
    <ul>
      <DefElement :body="body.intro" v-if="body.intro" :dictionary="dictionary" />
      <li
        :key="index"
        v-for="(item, index) in body.elements"
        >{{' '}}<router-link
          :to="'/' + dictionary + '/' + item.article_id + (item.definition_id ? '#def'+item.definition_id : '')"
          @click.native="article_link_click(item)"
          >{{item.lemmas[0].lemma}}</router-link>
      </li>
    </ul>
  </li>
</template>

<script>
import DefElement from './DefElement.vue'

export default {
  name: 'CompoundList',
  props: {
    body: Object,
    dictionary: String
  },
  components: {
    DefElement
  },
  methods: {
    article_link_click: function(item) {
      this.$emit('article-click', item)
    }
  }
}
</script>
<style scoped>
  li.compound_list ul li {
    display: inline;
  }

  li.compound_list li:not(:last-child):not(:first-child):after {
    content: ",";
  }

  ul {
    padding-left: 0px !important;
  }
</style>
