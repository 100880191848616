<template>
  <v-app id="app">
    <router-link ref="skip_link" to="#main" class="skip-link" @click.native="focus_main">{{$t('accessibility.main_content')}}</router-link>

    <div>
      <TopBar/>
    </div>
    
    <div v-if="$route.name!='about'" class = "banner" :class="$vuetify.breakpoint">
      <div v-if="show_banner_text">
        <a href="/">
      <h1 :class="$vuetify.breakpoint.name">Ordbøkene</h1></a>
      <p class="sub-title">{{$t("sub_title")}}</p>
        </div>
    </div>
    <router-view ref="router_view"></router-view>
    <footer :class="$vuetify.breakpoint.name">
      <div id="photo-attribution" aria-hidden="true" v-if="!$route.name && $vuetify.breakpoint.mdAndUp">{{$t('photo')}}</div>
      <div class="footer-row">
      <div class="logos" :class="$vuetify.breakpoint.xs?'sm':'lg'">
          <img id="srlogo" src="./assets/Sprakradet_logo_neg.png" alt="Språkrådet, logo">
          <img id="uiblogo" src="./assets/uib-logo.svg" alt="Universitetet i Bergen, logo">
      </div>
      <div class="footer-text"><em>{{$t('dicts.bm')}}</em>{{$t('and')}}<em>{{$t('dicts.nn')}}</em>{{$t('footer_description')}}
      </div>
      <FooterMenu />
      
      
      
      
      <div></div></div>
      <div class="accessibility-declaration"><a href="https://uustatus.no/nb/erklaringer/publisert/b2a6f8d0-3a16-4716-8bc8-46ac3c161935" target="_blank">{{$t('accessibility_statement')}}</a><v-icon color="white" small>open_in_new</v-icon></div>
      

    </footer>
  </v-app>
</template>
<script>
import TopBar from './components/TopBar.vue'
import FooterMenu from './components/FooterMenu.vue'

  export default {
    metaInfo() {
      return { htmlAttrs: {lang: {nob: "nb", nno: "nn", eng: "en", ukr: "uk"}[this.$i18n.locale]}}

    },
    computed: {
      show_banner_text: function() {
        return !this.$route.name || (window.innerHeight > 800 && this.$route.name != 'about' )
      }
    },
    components: {
      TopBar,
      FooterMenu
    },
    data: function() {
    return {
        contact_dialog: false,
        help_dialog: false,
        announcement: localStorage.getItem('app_info') == 'false' ? false : true,
        announce_test: localStorage.getItem('beta_info') == 'false' ? false : true
      }
    },
    methods: {
    close_announcement: function() {
      localStorage.setItem('app_info', 'false')
      this.announcement = false
    },
    close_test_announcement: function() {
      localStorage.setItem('beta_info', 'false')
      this.announce_test = false
    },
      focus_main: function() {
        this.$refs.router_view.$refs.main.focus();
      }
    }
  }
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./assets/o-0NIpQlx3QUlC5A4PNjXhFVZNyBx2pqPA.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inria Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fC1lPYxPY3rXxEndZJAzN3Srdy0.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inria Serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./assets/fC14PYxPY3rXxEndZJAzN3wQUjjCjl0.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/o-0IIpQlx3QUlC5A4PNr5TRA.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/o-0OIpQlx3QUlC5A4PNr4ARCQ_k.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: NotoParen;
  font-style: italic;
  src: url('./assets/NotoSansParen.woff') format('woff');
  unicode-range: U+28-29;
}


#app {
  font-family: NotoParen, 'Noto Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--v-text-base);

  background-color: var(--v-tertiary-base)
}

html, body {
    height: 100%
}

main {
  flex: 1;
}



h1, .article h3 {
  font-family: Inria Serif;
  padding-left: 15px;
  color: var(--v-primary-base)
}

p a, .article a, .search_notification a,  .did_you_mean a, .info-card a  {
  color: var(--v-text-base) !important;
  text-decoration: none;
  border-bottom: 1px solid var(--v-anchor-base);

}

p a:hover, .article a:hover, .search_notification a:hover, .info-card a:hover {
  color: var(--v-anchor-base) !important;
}

a.article_header {
  border: none;
}

.banner h1 {
  font-size: 48px;
}

h1.xs, h1.sm {
  font-size: 36px;
}

.banner {
  padding-top: 10px;
}



.v-list-item__title {
  word-break: break-all;
  overflow-wrap: break-word;
  
}


.banner p {
  padding-bottom: 10px;
  padding-top: 0;
  padding-left: 18px;

}

.banner a {
  text-decoration: none;
  color: var(--v-primary-base) !important;
}

.beta {
  font-family: Inria Serif;
  font-size: 18px;
  color: white;
  margin-left: 10px;
}

.language-dialog-title {
  font-family: Inria Serif;
  color: white;
}


.top-bar .v-toolbar__content {
    padding-left: calc((100vw - 1200px) / 2) !important;
    padding-right: calc((100vw - 1200px) / 2) !important;
}

.banner {
  position: relative;
  padding-left: calc((100vw - 1200px) / 2);
  padding-right: calc((100vw - 1200px) / 2);
  background-color: var(--v-tertiary-base);
}

.banner.xs, .banner.sm {
  padding-top: 0px;
}
.banner.xs div, .banner.sm div{
  display: none;
}

.sub-title {
  font-size: 18px;
  margin: 0px;
  margin-bottom: 0px !important;

}

.sub-title {
  color: var(--v-primary-base) !important;
}


footer  a, .notification a {
  color: #ffffff !important;
}

footer {
  padding-left: calc((100vw - 1200px) / 2);
  padding-right: calc((100vw - 1200px) / 2);
  font-size: smaller;

  background-color: var(--v-primary-base);
  color: #ffffff;
  padding-bottom: 10px;
}



.logos {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: 10px;
  padding: 10px;
  padding-left: 24px;
}

.accessibility-declaration {
 text-align: center;
 padding-top: 10px;
}

.footer-row {
  display: flex;
  flex-direction: rows;
  align-content: center;
  align-items:center;
  padding-top: 24px;
  gap: 10px;
}


footer.sm .footer-row, footer.xs .footer-row{
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  gap: 10px;
  

}

footer.sm div, footer.xs div {
  justify-content: center;
  justify-items: center;
  

}


#srlogo {
  height: 20px;
}

#uiblogo {
  height: 60px;
}


.v-btn {
  font-weight: bold !important;
}

.article .v-btn:focus, .v-dialog .v-btn:focus {
  outline: solid 2px var(--v-primary-base) !important;
}


/* all inflection-table css shoud be moved to beta.ordbok */
.infl-table caption {
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

td[class="infl-group"] {
  background-color: var(--v-button-base) !important;
  color: var(--v-text-base) !important;
  font-style: normal !important;
  font-weight: bold;
}

th[class="infl-group"] {
  background-color: var(--v-button-base) !important;
  font-style: unset;
}

.v-application .rounded-t-xl {
  border-top-left-radius: 28px !important;
  border-top-right-radius: 28px !important;
}

.v-application .rounded-xl {
  border-radius: 28px !important;
}

.theme--light.v-label, .theme--light.v-subheader, .transparent-text{
  color: var(--v-text-base)
}


#photo-attribution {
  position: absolute;

  right: 0px;
  width: 100%;
  text-align: right;
  padding-right: 6px;
  padding-top: 1px;
  padding-right: 6px;
  color: white;
  font-size: smaller;

}

.article h5, .about h3 {
  color: var(--v-primary-base);
  font-size: 14px;
  padding-left: 12px;
  padding-top: 6px;
}

.article h4, .monthly-title h2, .about h2, .v-dialog h2 {
  font-size: 1.17em;
  color: var(--v-primary-base);
  font-variant: all-small-caps;
}

.skip-link {
  position: absolute;
  top: -10
}

.skip-link:focus {
  position: unset;
  top: unset;
  text-align: center;
  padding: 10px;
  width: 100%;
  color: white;
  background-color: var(--v-secondary-base)
}

.v-alert {
  border-radius: 0px !important;
}


</style>
