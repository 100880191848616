var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.$route.name!='about')?_c('v-btn',{attrs:{"to":"/om","small":"","dark":"","text":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("info")]),_vm._v(_vm._s(_vm.$t('menu.about'))+" ")],1):_vm._e(),(_vm.$route.name=='about')?_c('v-btn',{attrs:{"to":"/","small":"","dark":"","text":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("home")]),_vm._v(_vm._s(_vm.$t('home'))+" ")],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("help")]),_vm._v(_vm._s(_vm.$t('menu.help'))+" ")],1)]}}]),model:{value:(_vm.help_dialog),callback:function ($$v) {_vm.help_dialog=$$v},expression:"help_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"elevation":"0","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('menu.help')))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":""},nativeOn:{"click":function($event){_vm.help_dialog=false}}},[_vm._v(_vm._s(_vm.$t('close'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("close")])],1)],1)],1),_c('v-card-text',{staticClass:"text--primary"},[_c('br'),_c('Help')],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("settings")]),_c('span',[_vm._v(_vm._s(_vm.$t('settings.title')))])],1)]}}]),model:{value:(_vm.settings_dialog),callback:function ($$v) {_vm.settings_dialog=$$v},expression:"settings_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"elevation":"0","dark":"","color":"primary"}},[_c('v-toolbar-title',[_c('span',{attrs:{"role":"heading","aria-level":"1"}},[_vm._v(_vm._s(_vm.$t('settings.title')))])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":""},nativeOn:{"click":function($event){_vm.settings_dialog=false}}},[_vm._v(_vm._s(_vm.$t('close'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("close")])],1)],1)],1),_c('Settings')],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mail")]),_c('span',[_vm._v(_vm._s(_vm.$t('contact.title')))])],1)]}}]),model:{value:(_vm.contact_dialog),callback:function ($$v) {_vm.contact_dialog=$$v},expression:"contact_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"elevation":"0","dark":"","color":"primary"}},[_c('v-toolbar-title',[_c('span',{attrs:{"role":"heading","aria-level":"1"}},[_vm._v(_vm._s(_vm.$t('contact.title')))])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":""},nativeOn:{"click":function($event){_vm.contact_dialog=false}}},[_vm._v(_vm._s(_vm.$t('close'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("close")])],1)],1)],1),_c('Contact',{on:{"close":function($event){_vm.contact_dialog = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }