<template>
<div v-if="article" :lang="{nob:'nb', nno: 'nn', eng: 'en', 'ukr': 'uk'}[dictionary]" class="article-container">
  <span :lang="lang_tag_locale" v-if="$vuetify.breakpoint.smAndDown || !$route.name || $route.name == 'lookup'" class="dict-label" role="heading" aria-level="2">{{dict_label}}</span>
  <div class="article" v-bind:class="{'expanded': !collapsed && collapsable, 'collapsable': collapsable, 'hide-label': hide_label, 'v-sheet v-card rounded-xl': !$parent.article}" v-if="article">
    <div :class="$vuetify.breakpoint.name" v-if="!invalid">
    <Header :title_id="title_id" :lemmas="article.lemmas" :dictionary="dictionary" :article_id="article.article_id" @toggle-collapse = "toggle_collapse"/>

    <div class="article_content" :class="$vuetify.breakpoint.name" v-show="!collapsed" ref="article_content">
      <section v-if="article.body.pronunciation && article.body.pronunciation.length" class="pronunciation">
        <h4 :lang="lang_tag_locale">{{$t('article.headings.pronunciation', content_locale)}}</h4>
          <ul>
            <DefElement v-for="(element, index) in article.body.pronunciation" :dictionary="dictionary" :key="index" :body='element' @article-click="article_link_click" @error="article_error"/>
          </ul>
      </section>
      <section v-if="article.body.etymology && article.body.etymology.length" class="etymology">
        <h4 :lang="lang_tag_locale">{{$t('article.headings.etymology', content_locale)}}</h4>
          <ul>
            <DefElement v-for="(element, index) in article.body.etymology" :dictionary="dictionary" :key="index" :body='element' @article-click="article_link_click"  @error="article_error"/>
          </ul>
      </section>
      <section class="definitions" v-if="has_content">
        <h4 :lang="lang_tag_locale">{{$t('article.headings.definitions', content_locale)}}</h4>
        <ol>
          <Definition v-for="definition in article.body.definitions" :dictionary="dictionary" :level="1" :key="definition.id" :body='definition' @article-click="article_link_click"  @error="article_error"/>
        </ol>
      </section>
      <section v-if="sub_articles.length" class="expressions">
        <h4 :lang="lang_tag_locale">{{$t('article.headings.expressions', content_locale)}}</h4>
        <ul>
          <SubArticle :body="subart" v-for="(subart, index) in sub_articles" :dictionary="dictionary" :key="index" @article-click="article_link_click"  @error="article_error"/>
        </ul>
      </section>
    </div>
    <ArticleFooter v-if="!collapsed" :article="article"/>
    </div>
    <div v-else><v-icon left>warning</v-icon> {{$t('error.article', {no: article.article_id, dict: $t('dicts_inline.'+this.dictionary)})}}</div>
  </div>
</div>
</template>

<script>
import DefElement from './DefElement.vue'
import Definition from './Definition.vue'
import SubArticle from './SubArticle.vue'
import Header from './Header.vue'
import ArticleFooter from './ArticleFooter.vue'
import entities from '../utils/entities.js'
import Mark from 'mark.js';

function find_sub_articles(definition) {
  let sub_art_list = []
  try {
    let sub_definitions = definition.elements.filter(el => el.type_ == 'definition')
    let sub_articles = definition.elements.filter(el => el.type_ == 'sub_article' && el.lemmas)

      sub_definitions.forEach((subdef, i) => {
        sub_art_list = sub_art_list.concat(find_sub_articles(subdef))
      })
      sub_art_list = sub_art_list.concat(sub_articles)
  return sub_art_list

  }
  catch(error) {
    console.log("find_sub_articles", this.article.article_id, this.dictionary,  '"'+error.message+'"')

    return []
  }
}


export default {
  name: 'Article',
  props: {
    article: Object,
    articleLookup: Boolean,
    title_id: String,
    queryPattern: String,
    scope: String,
  },
  data: function() {
    return {
      is_collapsed: true,
      invalid: false
    }
  },
  metaInfo() {
    if (this.articleLookup) {
      return {title: this.article.lemmas[0].lemma + ' | ' + {"bm,nn": "Bokmålsordboka og Nynorskordboka", "bm": "Bokmålsordboka", "nn": "Nynorskordboka"}[this.dictionary],
              meta: [{name: 'description', vmid: 'description', content: this.meta_description}],
              link: [{rel: "canonical", href: `https://ordbokene.no/${this.article.dictionary}/${this.article.article_id}`} ]
      }
    }
  },
  computed: {
    hide_label: function() {
      //collapsable || (collapsed && $store.state.collapseArticles != 'never')
      if (this.$parent.count_bm || this.$parent.count_nn) {
        let two_results = (this.$parent.count_bm + this.$parent.count_nn) < 3
        if (two_results) {
          return false
        }
        else if (this.$vuetify.breakpoint.mdAndUp && (this.collapsed || this.$store.state.collapseArticles == 'never')) {
          return true
        }
        else {
          return false
        }
      }
    },
    dictionary: function() {
      return this.article.dictionary
    },
    content_locale: function() {
      if (this.$i18n.locale == 'eng') {
        return 'eng'
      } else if (this.$i18n.locale == 'ukr') {
        return 'ukr'
      } else {
        return {bm: 'nob', nn: 'nno'}[this.dictionary]
      }
    },
    fulltext_highlight: function() {
      return this.$store.state.fulltextHighlight
    },
    collapsable: function() {
      if (this.$parent.$options.name != 'SearchResults') {
        this.is_collapsed = false
        return false
      }
      let collapsable = this.$store.state.collapseArticles
      if (collapsable == 'never') {
        this.is_collapsed = false
        return false
      }
      if (collapsable == 'always') {
        this.is_collapsed = true
        return true
      }
      if (collapsable == 'auto') {
        this.is_collapsed = this.$parent.$options.name == 'SearchResults' && (this.$parent.results_bm.length + this.$parent.results_nn.length > 2)
        return this.$parent.$options.name == 'SearchResults' && (this.$parent.results_bm.length + this.$parent.results_nn.length > 2)
      }
    },
    collapsed: {
      get() {
        if (this.$parent.$options.name != 'SearchResults') {
          return false
        }
        if (!this.collapsable) {
          this.is_collapsed = false
        }
        return this.is_collapsed
      },
      set(value) {
        this.is_collapsed = value
      }
    },
    snippet: function() {
    if (this.collapsable && this.article.body.definitions) {
      return this.meta_description
    }
    return null
    },
    meta_description: function() {
      return this.parse_definitions(this.article.body.definitions)
    },
    link_to_self: function() {
      try {
      return  {
        ref: '/' + this.dictionary + '/' + this.article.article_id,
        article: this.article
      }
      } catch(error) {
        console.log("link_to_self",this.article.article_id, this.dictionary,  '"'+error.message+'"')
        this.invalid = true
        //console.error(error)
        return {ref: "", article: this.article}
      }
    },
    lang_tag_locale: function() {
        return {nob: "nb", nno: "nn", eng: "en", ukr: "uk"}[this.content_locale]
    },
    dict_label: function() {
      let label = ''
      const dictionary = this?.article?.dictionary
      if (dictionary) {
        if (this.$route.name) {
          label = this.$t(`dicts.${dictionary}`, this.content_locale)
        } else {
          label = this.$t('from', this.content_locale) + " " + this.$t(`dicts_from.${dictionary}`, this.content_locale)
        }
      }
      return label
    },
    sub_articles: function() {
      return this.article.body.definitions.reduce((acc, val) => acc.concat(find_sub_articles(val)), []).sort((s1, s2) => s1.lemmas[0].localeCompare(s2.lemmas[0]))
    },
    has_content: function() {
      for (const definition of this.article.body.definitions) {
        for (const element of definition.elements) {
          if (['explanation', 'example', 'compound_list', 'definition'].includes(element.type_)) {
            return true
          }
        }
      }
      return false
    }
  },
  components: {
    DefElement,
    Definition,
    SubArticle,
    Header,
    ArticleFooter
  },
  mounted: function() {
    if (this.scope && this.scope.includes("f")) {
      let instance = new Mark(this.$refs.article_content)
      if (/[_%|]/.test(this.queryPattern)) {
        instance.markRegExp(new RegExp(this.queryPattern), {acrossElements: true, separateWordSearch:false});
      }
      else {
        instance.mark(this.queryPattern, {acrossElements: true, separateWordSearch:false, accuracy: 'exact', wildcards: 'enabled'});
      }

    }


    if (this.$route.hash == "#"+ this.title_id) {
      let focused = document.getElementById(this.title_id)
      if (focused) focused.focus()
    }
    else if (this.$route.hash) {
      let focused =document.getElementById(this.$route.hash.replace("#",""))
      if (focused) focused.scrollIntoView({block: "center"})
    }
  },
  methods: {
    article_error: function(payload) {
      console.log("DefElement",payload.location, this.article.article_id, this.dictionary,  '"'+payload.message+'"')
    },
    parse_subitems: function(explanation, text) {
          let new_string = ""
          let old_parts = text.split(/(\$)/)
          let linkIndex = 0
          let self = this

          old_parts.forEach((item) => {
            if (item == '$') {
              let subitem = explanation.items[linkIndex]
              if (/^\d$/.test(subitem.text)) {
                if (subitem.type_ == "superscript") {
                new_string += "⁰¹²³⁴⁵⁶⁷⁸⁹"[parseInt(subitem.text)]
                }
                else if (subitem.type_ == "subscript") {
                  new_string += "₀₁₂₃₄₅₆₇₈₉"[parseInt(subitem.text)]
                }
              }

              else if (subitem.id) {
                new_string += entities[self.dictionary][explanation.items[linkIndex].id].expansion

              }
              else if (subitem.text) {
                 if (subitem.text.includes('$')) {
                   new_string += self.parse_subitems(subitem, subitem.text)
                 }
                 else new_string += subitem.text
              }
              else  {
                if (explanation.items[linkIndex].lemmas) {
                new_string +=  explanation.items[linkIndex].word_form || explanation.items[linkIndex].lemmas[0].lemma
                }
              }
              linkIndex += 1
            }
            else {
              new_string += item
            }
          })
          return new_string

    },
    parse_definitions: function(node) {
      let definitionTexts = []
      let self = this
      try {
      node.forEach((definition) => {
        if (definition.elements) {
        if (definition.elements[0].content) {
          let new_string = self.parse_subitems(definition.elements[0], definition.elements[0].content)
          if (new_string.substring(new_string.length, new_string.length - 1) == ":") {
            new_string = new_string.slice(0, -1)
          }
          definitionTexts.push(new_string)

        }
        else if (definition.elements[0].elements) {
          definitionTexts.push(self.parse_definitions(definition.elements))
        }
      }
      })
      } catch(error) {
        console.log("parse_definitions",this.article.article_id, this.dictionary,  '"'+error.message+'"')
        this.invalid = true
        definitionTexts = []
      }

      let snippet = definitionTexts.join("\u00A0•\u00A0")
      return snippet

    },
    toggle_collapse: function() {
      if (this.collapsed) {
        this.$plausible.trackEvent('expand article', {props: {article: `${this.dictionary} ${this.article.article_id}`}})
      }
      this.collapsed = !this.collapsed
    },

    article_link_click: function(item) {
      this.$emit('article-click', item)
    },
    details_click: function(item) {
      item.title_id = this.title_id
      this.$emit('details-click', item)
    }
  }
}
</script>

<style>
.article {
  position: relative;
  padding: 24px;
  padding-bottom: 12px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #ffffff;
}

section.xs .article, section.sm .article {
  margin-bottom: 10px !important;
}

section.md .article, section.lg .article, section.xl .article {
  padding-top: 10px;
}


.welcome .article_footer {
  display: block;
}

#single_article_container .article {
  border: none;
  margin-top: 10px;
}


section {
  padding-top: 10px;
  padding-bottom: 10px
}


section.etymology > h4, section.pronunciation > h4 {
  display: inline;
}

section.etymology ul, section.pronunciation ul, section.etymology li, section.pronunciation li {
  display: inline;
}

section.etymology li:not(:first-child):not(:last-child):before, section.pronunciation li:not(:first-child):not(:last-child):before {
  content: ", ";
}

section.etymology li:not(:first-child):last-child:before, section.pronunciation li:not(:first-child):last-child:before {
  content: "; ";
  font-size: smaller;
}

li {
  padding-bottom: 4px;
}

li.level1.definition {
  list-style: upper-alpha;
}

li.level2.definition {
  list-style: decimal;
}

li.level3.definition {
  /* Norsk ordbok skal ha "lower.alpha" her */
  list-style: disc;
}

li.sub_article > ul {
  padding-left: 0px;
}

::marker {
  font-weight: bold;
  color: var(--v-primary-base);
}

ol > li:only-child.level1, li:only-child.level2 {
  /* level3 a.k.a. underdefinisjoner skal vises med bullet selv om de står alene */
  list-style: none;
}

li:only-child.level1 > ol {
  padding-left: 0px;
}

ul, ol {
  padding-left: 12px !important;
}

ul li {
  list-style:none;
}

ul li.definition {
  list-style: disc;
}



.choose {
  color: var(--v-primary-base) !important;
  text-decoration: none;
}

.info-card {
  padding: 12px;
}

.expanded {
  padding-bottom: 34px;
}


.header {
  border-radius: 0px !important;
}

.dict-label {
  color: var(--v-primary-base)  ;
  font-weight: bold;
  position: absolute;
  padding-left: 34px;
  margin-top: -2px;
  z-index: 2;
  font-variant-caps: all-small-caps;
  font-size: 1.17em;
}


</style>
