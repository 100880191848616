<template>

<v-list>
            <v-list-item>
              <div>
              <h2>{{$t('contact.content[0]')}}</h2><p>
              {{$t('contact.content[2]')}} <a href="mailto:ordbok@uib.no">ordbok@uib.no</a></p>
              <h2>{{$t('contact.content[1]')}}</h2><p>
              {{$t('contact.content[3]')}} <span style="white-space: nowrap;"><a href="mailto:ordbok-teknisk@uib.no">ordbok-teknisk@uib.no</a></span></p>
              
              </div>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
               <h2>{{$t('contact.faq.title')}}</h2>
              </v-list-item-title>
            </v-list-item>

             <v-list-group dense prepend-icon="help">
                 <template v-slot:activator>
            <v-list-item-content>{{$t('contact.faq.items[0].title')}}</v-list-item-content>
            
          </template>
    <v-list-item><p><em>{{$t('dicts.bm')}}</em>{{$t('and')}}<em>{{$t('dicts.nn')}}</em>{{$t('contact.faq.items[0].text[0]')}}<router-link to="/om" @click.native="$emit('close')">{{$t('contact.faq.items[0].text[1]')}}</router-link></p></v-list-item>
               </v-list-group>

            <v-list-group prepend-icon="help">
                 <template v-slot:activator>
            <v-list-item-content>{{$t('contact.faq.items[1].title')}}</v-list-item-content>
          </template>
    <v-list-item>{{$t('contact.faq.items[1].text')}}</v-list-item>
               </v-list-group>
               <v-list-group prepend-icon="help">
                 <template v-slot:activator>
            <v-list-item-content>{{$t('contact.faq.items[2].title')}}</v-list-item-content>
          </template>
    <v-list-item>{{$t('contact.faq.items[2].text')}}</v-list-item>
               </v-list-group>
          </v-list>

</template>

<script>


  export default {
    name: "Contact",
  }
</script>



