<template>
<div v-bind:class="{'centered': bottom}">
              <span color="tertiary" class = "pagination">
            <v-pagination @input="update" v-model="$parent.$parent.page" :class="$vuetify.breakpoint.name" :total-visible="$vuetify.breakpoint.smAndDown && !bottom ? 5 : 8" circle :length="Math.ceil(Math.max($parent.count_bm, $parent.count_nn)/$parent.$parent.perPage)"></v-pagination>
                <span class="result-counts" v-if="!bottom && $vuetify.breakpoint.smAndDown">
                  <span class="total-results">{{$parent.$parent.total_results()}} treff </span>
                  <span v-if="$parent.$parent.lang=='bm,nn'" class="dict-counts"> | {{$vuetify.breakpoint.mdAndDown? 'bokmål': 'Bokmålsordboka'}}: {{$parent.count_bm}} | {{$vuetify.breakpoint.mdAndDown? 'nynorsk': 'Nynorskordboka'}}: {{$parent.count_nn}}</span>
                  <span v-if="$parent.$parent.lang!='bm,nn'" class="dict-counts"> | {{$t('dicts_inline.'+$parent.lang)}}</span>
                </span> 
          </span>
          <v-btn v-if="bottom && $parent.$parent.total_results() > 6" text @click.native="to_top"><v-icon left>expand_less</v-icon>{{$t('to_top')}}</v-btn> 
          </div>
</template>


<script>

export default ({
    name: "Pagination",
    props: {
        bottom: Boolean
    },
    methods: {
        update: function(item) {
        if (this.bottom) {
            this.to_top()
        }
      
        this.$emit('update-page', item)

    },
        to_top: function() {
        let first = document.getElementById("result0")
        if (first) first.focus();
        window.scrollTo(0,0)
        }
        

    },
})
</script>

<style scoped>

.centered {
    text-align: center;
    padding-bottom: 10px;
}

.centered > span {
    justify-content: center;
    padding-bottom: 10px
}


</style>