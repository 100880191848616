<template>
<span>
      <v-btn v-if="$route.name!='about'" to="/om" small dark text>
              <v-icon small left>info</v-icon>{{$t('menu.about')}}
         </v-btn>
               <v-btn v-if="$route.name=='about'" to="/" small dark text>
              <v-icon small left>home</v-icon>{{$t('home')}}
         </v-btn>

                   <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" max-width="800px" v-model="help_dialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark text v-on="on" v-bind="attrs">
              <v-icon small left>help</v-icon>{{$t('menu.help')}}
         </v-btn>
        </template>
        <v-card>
          <v-toolbar elevation="0" dark color="primary">
            <v-toolbar-title>{{$t('menu.help')}}</v-toolbar-title><v-spacer></v-spacer>
            <v-toolbar-items><v-btn @click.native="help_dialog=false" text>{{$t('close')}}<v-icon right>close</v-icon></v-btn></v-toolbar-items>
          </v-toolbar>
          <v-card-text class="text--primary">
            <br>
          <Help/>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" max-width="800px" v-model="settings_dialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark text v-on="on" v-bind="attrs">
              <v-icon small left>settings</v-icon><span>{{$t('settings.title')}}</span>
         </v-btn>
        </template>
        <v-card>
          <v-toolbar elevation="0" dark color="primary">
            <v-toolbar-title><span  role="heading" aria-level="1">{{$t('settings.title')}}</span></v-toolbar-title><v-spacer></v-spacer>
            <v-toolbar-items><v-btn @click.native="settings_dialog=false" text>{{$t('close')}}<v-icon right>close</v-icon></v-btn></v-toolbar-items>
          </v-toolbar>
          <Settings/>
        </v-card>
      </v-dialog>

          <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" max-width="800px" v-model="contact_dialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small dark text v-on="on" v-bind="attrs">
              <v-icon small left>mail</v-icon><span>{{$t('contact.title')}}</span>
         </v-btn>
        </template>
        <v-card>
          <v-toolbar elevation="0" dark color="primary">
            <v-toolbar-title><span  role="heading" aria-level="1">{{$t('contact.title')}}</span></v-toolbar-title><v-spacer></v-spacer>
            <v-toolbar-items><v-btn @click.native="contact_dialog=false" text>{{$t('close')}}<v-icon right>close</v-icon></v-btn></v-toolbar-items>
          </v-toolbar>
          <Contact @close="contact_dialog = false"/>
        </v-card>
      </v-dialog>
      
</span>
</div>
</template>
<script>
import Help from './Help.vue'
import Contact from './Contact.vue'
import Settings from './Settings.vue'

  export default {
    name: "FooterMenu",
    components: {
      Help,
      Contact,
      Settings
    },
    data: function() {
    return {
      contact_dialog: false,
      help_dialog: false,
      settings_dialog: false
    }
    }
  }
</script>