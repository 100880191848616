<template>
  <li :class="['definition', 'level'+level]"  :ref="level != 9 ? 'def' + body.id : ''" :id="level != 9? 'def' + body.id : ''">
    <span v-if="level!=9"/>
    <ul class="explanations">
      <DefElement :body="explanation" :dictionary="dictionary" :has_article_ref=has_article_ref(explanation) v-for="(explanation, index) in explanations" :key="index" @article-click="article_link_click" />
    </ul>
    <div v-if="examples.length">
      <h5 :lang="lang_tag_locale" v-if="level < 3">{{$t('article.headings.examples', content_locale)}}</h5>
      <ul class="examples">
        <Example :body="example" :dictionary="dictionary" v-for="(example, index) in examples" :key="index" @article-click="article_link_click" />
      </ul>
    </div>
    <ul class="compound_lists">
      <CompoundList :body="compound_list" :dictionary="dictionary" v-for="(compound_list, index) in compund_lists" :key="index" @article-click="article_link_click" />
    </ul>
    <div :is="level < 3 ? 'ol' : 'ul'" class="sub_definitions" v-if="subdefs.length">
      <Definition :def_number='index+1' :level="level+1" :body="subdef" v-for="(subdef, index) in subdefs"  :dictionary="dictionary" :key="index" @article-click="article_link_click" />
    </div>
  </li>
</template>

<script>
import DefElement from './DefElement.vue'
import Example from './Example.vue'
import CompoundList from './CompoundList.vue'

var Definition = {
  name: 'Definition',
  props: {
    body: Object,
    level: Number,
    dictionary: String,
    def_number: Number
    
  },
  components: {
    DefElement,
    Definition,
    Example,
    CompoundList
  },
  computed: {
    explanations: function() {
      try {
        return this.body.elements.filter(el => el.type_ == 'explanation')
      } catch (error) {
        this.$emit('error', {location: "explanations", message: error.message})
        return []
      }
    },
    examples: function() {
      try {
        return this.body.elements.filter(el => el.type_ == 'example')
      } catch (error) {
        this.$emit('error', {location: "examples", message: error.message})
        return []
      }
        
    },
    compund_lists: function() {
      try {
        return this.body.elements.filter(el => el.type_ == 'compound_list')
      } catch (error) {
        this.$emit('error', {location: "compound_lists", message: error.message})
        return []
      }
        
    },
    subdefs: function() {
      try {
        return this.body.elements.filter(el => el.type_ == 'definition').filter(def => def.elements.filter(el => el.type_ != 'sub_article').length > 0)

      } catch (error) {
        this.$emit('error', {location: "subdefs", message: error.message})
        return []
      }
      // filtrerer bort definisjoner som bare inneholder underartikler
      
    },
    content_locale: function() {
      return this.$parent.content_locale
    },
    lang_tag_locale: function() {
      return this.$parent.lang_tag_locale
    }
    
    },
  mounted: function() {
    let ref = 'def' + this.body.id
    if(location.hash.substring(1) == ref){
      this.$refs[ref].scrollIntoView({block: 'center'})
      this.$refs[ref].classList.add('highlighted')
    }
  },
  methods: {
    article_link_click: function(item) {
      this.$emit('article-click', item)
    },
    has_article_ref: function(item){
      if(item.items.length && item.items[0].type_ == "article_ref" && item.items[0].definition_id === undefined)
        {
          return "true";
        }
        else{
          return "false";
        }
     }
  },
  watch:{
    $route(to, from) {
      let ref = 'def' + this.body.id
      if (this.$refs[ref]) {
        if(location.hash.substring(1) == ref){
          this.$refs[ref].classList.add('highlighted')
        }else {
          this.$refs[ref].classList.remove('highlighted')
        }
      }
    }
  }
}

export default Definition
</script>
<style>
q {
  font-style: italic;
}

.highlighted, mark {
  background-color: var(--v-tertiary-darken1);
}

mark {
  font-weight: bold;
}


li[has_article_ref="true"] {
  margin-top: 8px;
  margin-left: -25px;
}

</style>
