<template>
          <v-card>
            <v-list>
               <v-list-group prepend-icon="help">
                 <template v-slot:activator>
            <v-list-item-title>{{$t('menu.help')}}</v-list-item-title>
          </template>
    <v-list-item><Help/></v-list-item>
               </v-list-group>

               <v-list-item link to="/om" v-on:click="$emit('close')">
            <v-list-item-icon><v-icon>info</v-icon></v-list-item-icon>
            <v-list-item-title>{{$t('menu.about')}}</v-list-item-title>
            <v-list-item-icon><v-icon>chevron_right</v-icon></v-list-item-icon>
              </v-list-item>




              <v-list-group sub prepend-icon="language">
                 <template v-slot:activator>
            <v-list-item-title>{{$t('settings.locale.title')}}</v-list-item-title>
          </template>
          <v-list-item>
     <v-list subheader>
      <v-radio-group v-model="updateLocale" :label="$t('settings.locale.description')">
        <v-radio value="nob" label="Bokmål (nob)"></v-radio>
        <v-radio value="nno" label="Nynorsk (nno)"></v-radio>
        <v-radio value="eng" label="English (eng)"></v-radio>
        <v-radio value="ukr" label="Українська (ukr)"></v-radio>
      </v-radio-group>
      </v-list></v-list-item>
              </v-list-group>

                            <v-list-group v-if="false" prepend-icon="book">
                 <template v-slot:activator>
            <v-list-item-title>{{$t('settings.dict.title')}}</v-list-item-title>
          </template>
      <v-list-item>
     <v-list subheader>
      <v-radio-group mandatory v-model="updateDefaultDict" :label="$t('settings.dict.description')">
        <v-radio value="bm,nn" :label="$t('dicts.bm,nn')"></v-radio>
        <v-radio value="bm" :label="$t('dicts.bm')"></v-radio>
        <v-radio value="nn" :label="$t('dicts.nn')"></v-radio>
      </v-radio-group>
      </v-list></v-list-item>
      </v-list-group>


<v-list-group prepend-icon="settings">
                 <template v-slot:activator>
            <v-list-item-title>{{$t('settings.title')}}</v-list-item-title>
          </template>

    <v-list-item><Settings/></v-list-item>
                
              </v-list-group>
              

                          <v-list-item v-if="!$store.state.unavailable" v-on:click='$store.commit("resetStore")'>
                <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                <v-list-item-title>{{$t("settings.reset")}}</v-list-item-title>

              </v-list-item>

                    <v-list-group prepend-icon="mail">
                 <template v-slot:activator>
            <v-list-item-title>{{$t('contact.title')}}</v-list-item-title>
          </template>

    <v-list-item><Contact @close="$emit('close')"/></v-list-item>
                
              </v-list-group>
              
                </v-list>

            

          </v-card>
</template>

<script>
import Settings from './Settings.vue'
import Help from './Help.vue'
import Contact from './Contact.vue'

export default {
    name: "Menu",
    components: {
      Settings,
      Help,
      Contact
    },
    computed: {
        updateLocale: {
            get () { return this.$store.state.currentLocale}, 
            set(value) {
                this.$store.commit("setLocale", {value: value, i18n: this._i18n})
                }
        },
        updateDefaultDict: {
            get () { return this.$store.state.defaultDict}, 
            set(value) {
                this.$store.commit("setDefaultDict", value)
                }
        }
    },
}
</script>
