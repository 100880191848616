<!-- eslint-disable -->
<template>
  <div class="header">
    <router-link v-if="$route.name != 'lookup'"
                 :id="title_id"
                 :to="$parent.link_to_self.ref"
                 @click.native="heading_click"
                 v-bind:class="{'long_lemma': long_lemma}"
                 class="article_header">
    <HeaderTitle :lemma_groups="lemma_groups" :secondary_header_text="secondary_header_text"/>
    </router-link> 
    <span v-else>
      <HeaderTitle :lemma_groups="lemma_groups"  :secondary_header_text="secondary_header_text"/>
    </span>
    <InflectionButton :lemmas="$parent.article.lemmas" :dictionary="dictionary" :article_id="$parent.article.article_id"/>

    <SplitInf v-if="!$parent.collapsed" :lemmas="lemmas"/>
    <p v-if="!lemma_groups[1] && $parent.collapsed && $parent.snippet && $parent.has_content" v-bind:class="{'under_inflection_button': !$store.state.inflectionExpanded}">
    {{$parent.snippet}}
    </p>

    <span v-if="$parent.collapsable" >
          <v-btn class="expand_icon" v-if="$parent.collapsed"
           text
           small
           right
           @click="$emit('toggle-collapse')">
           {{$t('article.show')}}
           <v-icon right>expand_more</v-icon></v-btn>
           <v-btn class="expand_icon" v-else
           text
           small
           @click="$emit('toggle-collapse')">
           <v-icon small>expand_less</v-icon></v-btn>

           </span>
  </div>
</template>

<script>
  /* eslint-disable */
import helpers from '../utils/helpers.js'
import SplitInf from './SplitInf.vue'
import InflectionButton from './InflectionButton.vue'
import HeaderTitle from './HeaderTitle.vue'

export default {
  name: 'Header',
  props: {
    lemmas: Array,
    dictionary: String,
    article_id: Number,
    title_id: String
  },
    data: function() {
    return {
      long_lemma: false
    }
  },
  components: { 
    SplitInf,
    InflectionButton,
    HeaderTitle
  },
  mounted: function() {
    if (this.$el.scrollWidth > this.$el.offsetWidth) {
      this.long_lemma = true
    }

  },
  methods: {
    heading_click: function() {
         this.$plausible.trackEvent('article head click', {props: {article: `${this.dictionary} ${this.article_id}`}})
        this.$parent.details_click(this.$parent.link_to_self)
    },
    inflection_classes: function(lemmas) {
      let inf_classes = new Set()
      let ureg = false
      lemmas.forEach((lemma, i) => {
        if (lemma.inflection_class) inf_classes.add(lemma.inflection_class)
        else ureg = true
      })
      if (inf_classes.size){

      let class_array = Array.from(inf_classes).sort()
      if (ureg) class_array.push("ureg.")
      let class_list
      if (class_array.length < 3) {
        class_list = class_array.join(" og ")
      }
      else {
        class_list = class_array.slice(0, -1).join(", ") + " og " + class_array[class_array.length -1]
      }
      return " ("+ class_list +")"
      }
    }
  },
  computed: {
    content_locale: function() {
      return this.$parent.content_locale
    },
    lang_tag_locale: function() {
      return this.$parent.lang_tag_locale
    },
    secondary_header_text: function() {
      let a_forms = []
      this.lemmas.forEach((lemma, i) => {
        if (lemma.paradigm_info[0] && lemma.paradigm_info[0].inflection[1] && lemma.paradigm_info[0].inflection[1].tags[0] == 'Inf') {
          let inf2 = lemma.paradigm_info[0].inflection[1].word_form
          if (inf2 && inf2.length) {
            a_forms.push(inf2)
          }
        }
      });
      return a_forms.join(', ')
    },
    hgno_arabic: function() {
    let hgnos = []
    this.lemmas.forEach(lemma => {
      let hgint = parseInt(lemma.hgno)
      if (hgint > 0) {
        hgnos.push(hgint)
        }
    })
    return hgnos
    },
    lemma_groups: function() {
      let groups = [{lemmas: this.lemmas}]
      try {
        if (this.lemmas[0].paradigm_info[0].tags[0] == "DET" && this.lemmas[0].paradigm_info[0].tags.length > 1) {
          groups = [{description: this.$t('tags.'+this.lemmas[0].paradigm_info[0].tags[0], this.content_locale), pos_group: ["Quant", "Dem", "Poss"].includes(this.lemmas[0].paradigm_info[0].tags[1]) ? this.$t('determiner.' + this.lemmas[0].paradigm_info[0].tags[1], this.content_locale) : '', lemmas: this.lemmas}]

        }
        else if (this.lemmas[0].paradigm_info[0].tags[0] == 'NOUN') {
            let genus_map  = {}
            let self = this
            this.lemmas.forEach(lemma =>{
              let genera = new Set()
              lemma.paradigm_info.forEach(paradigm => {
                if (paradigm.tags[1]) {
                  genera.add(paradigm.tags[1])
                }
              })
              let genus_description = ""
              if (genera.size == 3) {
                genus_description += self.$t('tags.Masc') + ', ' + self.$t('tags.Fem', self.content_locale) + self.$t('or') + self.$t('tags.Neuter', self.content_locale)
              } else {
                genus_description += Array.from(genera).map(code => self.$t('tags.'+code, self.content_locale)).sort().join(self.$t('or'))
              }
              if (genus_map[genus_description]) {
                genus_map[genus_description].push(lemma)
              }
              else {
                genus_map[genus_description] = [lemma]
              }
            })
            groups = Object.keys(genus_map).map(key => {
              return {description: self.$t('tags.NOUN', self.content_locale), pos_group: key, lemmas: genus_map[key], }
            })

        
        }
        else if (this.lemmas[0].paradigm_info[0].tags[0] != 'EXPR') {
          groups = [{description: this.$t('tags.'+this.lemmas[0].paradigm_info[0].tags[0], this.content_locale), lemmas: this.lemmas}]
        }
        
        groups.forEach((lemma_group, index) => {
              groups[index]['inflection_classes'] = this.inflection_classes(lemma_group.lemmas)
            })
    } catch(error) {
      console.log("lemma_groups",this.article_id, this.dictionary, '"'+error.message+'"')
      this.$parent.invalid = true
      //console.error(error)
    }
      return groups
    },
  }
}

</script>

<style>


.article h3 {
  padding-top: 4px !important;
  padding-bottom: 0px !important;
  font-family: Inria Serif;
}

.article .sm h3, .article .xs h3,  .long_lemma h3 {
  font-size: 1.3em;
}

.sm .long_lemma, .xs .long_lemma h3 {
  font-size: 1em;
}

.article h3 {
  font-size: 1.5em;
}



h3 a {
  text-decoration: none !important;
  color: var(--v-primary-base) !important;
}



.article h1.secondary_header {
  padding-top: 0px;
  padding-bottom: 4px;
}

.info-button {
  margin-left: 6px;
  margin-right: -2px;
  height: 10px;
  width: 10px;
}

.word-classification {
  text-decoration: underline dashed;
}




div.lemma {
  display: none;
}



.article_header {
  overflow:auto;
  word-wrap: normal;
  text-decoration: none;
}


.hgno {
  color: var(--v-text-base) !important;
  font-family: unset;
  font-size: 16px;
  font-weight: normal;
}


.expand_icon {
    justify-content: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding-right: 24px !important;
    width: 100%;
    border-bottom-right-radius: 28px;
    border-bottom-left-radius: 28px;
}

.subheader {
  color: var(--v-text-base);
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
  padding-right: 10px;
}

.inflection_classes {
  font-style: normal;
}


.header_group_list {
  font-variant: all-small-caps;
  font-style: normal;
  font-size: 18px;
}

p.under_inflection_button {
  padding-top:10px !important;
}



</style>
