<template>
  <li class="example">
    <DefElement tag="q" :body="body.quote"  @article-click="article_link_click" :dictionary="dictionary" /><span v-if="body.explanation && body.explanation.content.length"> – </span>
    <DefElement tag="span" :body="body.explanation" v-if="body.explanation && body.explanation.content.length" @article-click="article_link_click" :dictionary="dictionary" />
  </li>
</template>

<script>
import DefElement from './DefElement.vue'

export default {
  name: 'Example',
  props: {
    body: Object,
    dictionary: String
  },
  components: {
    DefElement
  },
  methods: {
    article_link_click: function(item) {
      this.$emit('article-click', item)
    }
  }
}

</script>
<style scoped>
ul {
    padding-left: 0px;
}
</style>
