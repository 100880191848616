<template>
<span>
     <span v-bind:class="{ 'lookup': $route.name=='lookup'}" v-for="(lemma_group, i) in lemma_groups" :key="i">
    <h3>
                <!--
    --><span v-for="(lemma, index) in lemma_group.lemmas"
          :key="index"><DefElement v-if="lemma.annotated_lemma" :body="lemma.annotated_lemma" tag="span"/><span v-else>{{lemma.lemma}}</span><!--
          --><span v-if="lemma.hgno"
                   :aria-label="$t('accessibility.homograph') + parseInt(lemma.hgno)"
                   :title="$t('accessibility.homograph')+parseInt(lemma.hgno)"
                   class="hgno">{{" "+roman_hgno(lemma)}}</span><!--
                    --><span
                   class="title_comma"
                   v-if="lemma_group.lemmas[1] && index < lemma_group.lemmas.length-1">{{", "}}
                  </span>
    </span>
</h3>
<h3 v-if="secondary_header_text">{{secondary_header_text}}</h3>  
    <span :lang="$parent.$parent.lang_tag_locale" v-if="lemma_group.description" class="subheader">
    <span class="header_group_list">{{lemma_group.description}}</span>
          {{lemma_group.pos_group}}
    <span v-if="$store.state.showInflectionNo" class="inflection_classes">{{lemma_group.inflection_classes}}</span>

    </span>


    </span>
</span>
</template>

<script>
import helpers from '../utils/helpers.js'
import DefElement from './DefElement.vue'

export default {
  name: 'HeaderTitle',
  components: {
      DefElement
  },
  props: {
      lemma_groups: Array,
      secondary_header_text: String
  },
  methods: {
      roman_hgno: helpers.roman_hgno,
      
  }
}

</script>

