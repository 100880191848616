<template>
<div>
<v-list subheader>
    <v-list-item>
    <v-checkbox
    v-model="toggleInflectionExpanded"
    :label="$t('settings.inflection_expanded')"
    hide-details>
    </v-checkbox>
    </v-list-item>
    <v-list-item>
    <v-checkbox
    v-model="toggleInflectionNo"
    :label="$t('settings.inflection_no')"
    hide-details>
    </v-checkbox>
    </v-list-item>
    <v-list-item>
    <v-checkbox
    v-model="disableHotkey"
    :label="$t('settings.disable_hotkey')"
    hide-details>
    </v-checkbox>
    </v-list-item>
      <v-list-item>
    <v-checkbox
    v-model="toggleInflectionTableContext"
    :label="$t('settings.inflection_table_context')"
    hide-details>
    </v-checkbox>
    </v-list-item>
    <v-list-item>

      <v-radio-group mandatory two-line v-model="updateCollapse" :label="$t('settings.collapse.description')">
        <v-radio value="auto" :label="$t('settings.collapse.auto')"></v-radio>
        <v-radio value="always" :label="$t('settings.collapse.always')"></v-radio>
        <v-radio value="never" :label="$t('settings.collapse.never')"></v-radio>
      </v-radio-group>
      </v-list-item>
      </v-list>
    </div>
</template>

<script>

export default {
    name: "Settings",
    computed: {
        toggleInflectionNo: {
            get () { return this.$store.state.showInflectionNo}, 
            set () { this.$store.commit("toggleInflectionNo")
            }
        },
        disableHotkey: {
            get () { return this.$store.state.disableHotkey}, 
            set () { this.$store.commit("disableHotkey")
            }
        },
        toggleInflectionExpanded: {
            get () { return this.$store.state.inflectionExpanded}, 
            set () { this.$store.commit("toggleInflectionExpanded")
            }
        },
        toggleInflectionTableContext: {
            
            get () { 
                return this.$store.state.inflectionTableContext}, 
            set () { 
                this.$store.commit("toggleInflectionTableContext")
            }
        },
        updateCollapse: {
            get () { return this.$store.state.collapseArticles}, 
            set(value) {
                this.$store.commit("setCollapse", value)
                }
        },
    },
}
</script>
