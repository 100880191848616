<template>
  <li class="sub_article">
    <span class="sub_article_header">
      {{body.lemmas[0]}}
    </span>
    <ul>
      <DefElement :body="body.intro" v-if="body.intro" :dictionary="dictionary" @article-click="article_link_click" @error="article_error"/>
      <Definition :level="9" :body="body.article.body.definitions[0]" :dictionary="dictionary" @article-click="article_link_click" @error="article_error"/>
    </ul>
  </li>
</template>

<script>
import DefElement from './DefElement.vue'

export default {
  name: 'SubArticle',
  props: {
    body: Object,
    dictionary: String
  },
  components: {
    DefElement
  },
  computed: {
    link_prefix: function() {
      return this.$route.params.lemma ? '../' : './'
    }
  },
  beforeCreate: function () {
    this.$options.components.Definition = require('./Definition.vue').default
  },
  methods: {
    article_link_click: function(item) {
      this.$emit('article-click', item)
    },
    article_error: function(payload) {
      this.$emit('error', payload)
    }
  }
}

</script>
<style scoped>
.sub_article_header {
  font-weight: bold;
  color: var(--v-primary-base);
}

li.sub_article {
  padding-top: 8px;
}

li.sub_article ul {
  padding-top: 0px;
}

li.sub_article ul li {
  list-style: none;
}

</style>
