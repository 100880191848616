<template>
  <div :lang="$parent.lang_tag_locale" class = "split-inf" v-if="split_inf">kløyvd infinitiv: <em>-a</em>
      <v-menu tile 
              v-model="menu" 
              offset-x max-width="200px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn :aria-label="$t('split_inf.label', $parent.content_locale)"
                 small
                 icon 
                 v-on="on" 
                 v-bind="attrs" 
                 class="info-button">
            <v-icon color="primary" 
                    small>info</v-icon>
          </v-btn>
        </template>
        <v-card tile 
                class="info-card">
          {{$t('split_inf.content[0]', $parent.content_locale)}} <em>-a</em> {{$t('split_inf.content[1]', $parent.content_locale)}}
          <a target="_blank" 
             href="https://www.sprakradet.no/svardatabase/sporsmal-og-svar/kloyvd-infinitiv-/">{{$t('split_inf.content[2]', $parent.content_locale)}}</a>
        </v-card>
      </v-menu>
    </div>
</template>

<script>

export default {
    name: "SplitInf",
    props: {
        lemmas: Array
    },
    data: function() {
    return {
      menu: false,
      long_lemma: false
    }
  },
    computed: {
          split_inf: function() {
      return this.lemmas[0].split_inf
      }
    }
  
}

</script>
<style>
.split-inf {
  padding-top: 10px;
}
</style>