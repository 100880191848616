<template>
  <div :lang="lang_tag_locale" class="article_footer">
     <v-snackbar centered
                max-width="300px"
                min-width="300px"
                max-height="36px"
                min-height="36px"
                rounded="pill"
                v-model='copy_popup'
                timeout="1000">

        <span class="text-center">{{$t(what_copied, content_locale)}}</span>
      </v-snackbar>

      <v-btn v-if="showLinkCopy"
             small text class="toolbar-button" rounded @click="copy_link">
        <v-icon small left>link</v-icon><span class = "button-text">{{$t("article.copy_link", content_locale)}}</span>
      </v-btn>

      <v-btn v-if="webShareApiSupported" text small class="toolbar-button" rounded @click="shareViaWebShare">
        <v-icon small left>share</v-icon><span class = "button-text">{{$t("article.share", content_locale)}}</span>
      </v-btn>

      <v-dialog max-width="600px" v-model="citation_dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn @click="track_citation" text small class="toolbar-button" rounded v-on="on" v-bind="attrs">
        <v-icon left small>format_quote</v-icon> <span class = "button-text">{{$t("article.cite", content_locale)}}</span>
      </v-btn>
      

        </template>

        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title><span role="heading" aria-level="1">{{$t('article.cite_title')}}</span></v-toolbar-title>
            <v-spacer></v-spacer><v-toolbar-items><v-btn @click="close_citation_dialog" text>{{$t('close')}}<v-icon right>close</v-icon></v-btn></v-toolbar-items></v-toolbar>
          <v-card-text class="text--primary">
          {{$t("article.cite_description[0]", content_locale)}}<em>{{$t('dicts.'+$parent.dictionary)}}</em>{{$t("article.cite_description[1]", content_locale)}}
          <br/>
          <div id = "citation" v-html="this.create_citation()"/>
          </v-card-text>
          <v-card-actions>
          <v-btn depressed small rounded @click="copy_citation"><br>
            <v-icon left small icon>content_copy</v-icon>  <span class = "button-text">{{$t("article.copy", content_locale)}}</span>
          </v-btn>
                    <v-btn depressed small rounded @click="download_ris"><br>
            <v-icon left small icon>get_app</v-icon> <span class = "button-text">{{$t("article.download")}}</span>
          </v-btn>
                    
          </v-card-actions>

        </v-card>

      </v-dialog>


  </div>
</template>

<script>
export default {
  name: 'ArticleFooter',
  props: {
    article: Object
  },
    computed: {
    webShareApiSupported() {
      return navigator.share
    },
    showLinkCopy() {
      return (!navigator.share || this.$vuetify.breakpoint.mdAndUp) && navigator.clipboard
    },
    hasPointer() {
      return window.matchMedia('(hover: hover) and (pointer: fine)').matches
    },
    content_locale: function() {
      return this.$parent.content_locale
    },
    lang_tag_locale: function() {
      return this.$parent.lang_tag_locale
    }
  },
  data: function() {
    return {
      copy_popup: false,
      citation_dialog: false,
      what_copied: null
    }

  },
  methods: {
    track_citation() {
      this.$plausible.trackEvent('citation click', {props: {article: `${this.$parent.dictionary} ${this.$parent.article.article_id}`}})
    },
    shareViaWebShare() {
      this.$plausible.trackEvent('webshare', {props: {article: `${this.$parent.dictionary} ${this.$parent.article.article_id}`}})
      navigator.share({
        title: "Ordbøkene.no: " + this.article.lemmas[0].lemma,
        text: "",
        url: "/" + this.article.dictionary + '/' + this.article.article_id
      })
    },
    create_link() {
      return 'https://ordbokene.no/' + this.article.dictionary + '/' + this.article.article_id
    },
    get_citation_info() {
      let date = new Date();
      let dd = (date.getDate() < 10? '0' : '') + date.getDate()
      let mm = (date.getMonth() < 9? '0' : '') + (date.getMonth()+1)
      let yyyy = date.getFullYear()
      let link = this.create_link()
      let lemma = this.article.lemmas[0].lemma
      let dict = this.$t(`dicts.${this.article.dictionary}`, this.content_locale)
      return [lemma, dd, mm, yyyy, link, dict]
    },
    create_citation() {
      const [lemma, dd, mm, yyyy, link, dict] = this.get_citation_info()
      let citation = this.$t("article.citation", {lemma, link, dd, mm, yyyy, dict})

      return citation
    },
    copy_link() {
      let link = this.create_link()
      console.log(`${this.$parent.dictionary} ${this.$parent.article.article_id}`)
      this.$plausible.trackEvent('copy link', {props: {article: `${this.$parent.dictionary} ${this.$parent.article.article_id}`}})
      let self = this
         navigator.clipboard.writeText(link).then(() => {
           self.what_copied = this.$t("article.link_copied")
           self.copy_popup = true
         }).catch(err => {
           console.log("ERROR COPYING:",err)
         })
    },

    copy_citation() {

      let citation = document.getElementById("citation").textContent;
      navigator.clipboard.writeText(citation)
      this.citation_dialog = false
      this.what_copied = this.$t("article.citation_copied")
      this.copy_popup = true
    },

    close_citation_dialog() {
      this.citation_dialog = false
    },
    download_ris() {
      const [lemma, dd, mm, yyyy, link] = this.get_citation_info()
      const a = document.createElement("a")
      a.style = "display: none"
      a.setAttribute("download", `${lemma}_${this.article.dictionary}.ris`)
      const dict = {"bm":"Bokmålsordboka", "nn": "Nynorskordboka"}[this.article.dictionary]
      const text = `TY  - DICT\nTI  - ${lemma}\nT2  - ${dict}\nPB  - Språkrådet og Universitetet i Bergen\nUR  - ${link}\nY2  - ${yyyy}/${mm}/${dd}/\nER  - `
      a.setAttribute('href', 'data:application/x-research-info-systems;charset=utf-8,' + encodeURIComponent(text));
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
  }
}

</script>
<style scoped>

.v-icon {
  color: var(--v-primary-base) !important;
}

.toolbar-button {
  margin-right: 8px;
  margin-top: 8px;
  font-size: 12px;
}


.article_footer {
  color: var(--v-primary-base);
  padding-top: 24px;
}

#citation {
  margin-top: 12px;
  padding: 12px;
  background-color: var(--v-button-base) !important;
  margin-bottom: 6px;
}

</style>
