<!-- eslint-disable -->
<template>

      <span :is="$vuetify.breakpoint.smAndDown ? 'div' : 'span'" class="inflection-wrapper" v-if="inflected">

    <v-btn :lang="$parent.$parent.lang_tag_locale" :aria-controls="'inflection-canvas'+$parent.title_id" :aria-expanded="inflection_expanded ? 'true' : 'false'" v-if="!($store.state.inflectionExpanded && $route.name)" class="show-inflection" small depressed rounded @click.native="toggle" :class="$vuetify.breakpoint.name">
    <span :lang="$parent.lang_tag_locale">{{inflection_expanded? $t('article.hide_inflection', $parent.content_locale):$t('article.show_inflection', $parent.content_locale)}}</span><span class = "inflection-arrow"><v-icon small right>{{this.inflection_expanded?  "remove" : "add"}}</v-icon></span>
    </v-btn>
      <div :id="'inflection-canvas'+$parent.title_id" class="inflection-canvas">
        <inflectionTable v-if="inflection_expanded || always_expand" :eng="$store.state.currentLocale == 'eng'" :ukr="$store.state.currentLocale == 'ukr'" :lemmaList="lemmas_with_word_class_and_lang" :mq="$vuetify.breakpoint.name" :context="$store.state.inflectionTableContext" :key="$store.state.currentLocale + $store.state.inflectionTableContext"/>
      </div>

</span>
</template>

<script>
  /* eslint-disable */
import inflectionTable from 'inflection-table'

export default {
  name: 'InflectionButton',
  props: {
    lemmas: Array,
    dictionary: String,
    article_id: Number
  },
  computed: {
    lemmas_with_word_class_and_lang: function() {
      return this.lemmas.map(lemma => Object.assign({language: this.dictionary == 'bm' ? 'nob' : 'nno',
                                                     word_class: lemma.paradigm_info[0].inflection_group.split('_')[0]}, lemma))
    },
    inflected: function() {
      return this.lemmas.reduce((acc, lemma) => acc + lemma.paradigm_info.reduce((acc2, digm) => digm.inflection_group.includes("uninfl") ? acc2 : acc2 + digm.inflection.length, 0), 0) > this.lemmas.length
    },
    always_expand: function() {
      return  !this.$parent.$parent.collapsed && this.$store.state.inflectionExpanded && this.$route.name
    }
  },
  components: {
    inflectionTable
  },
  data: function() {
    return {
      inflection_expanded: false
    }
  },
  methods: {
    toggle: function() {
      if (!this.inflection_expanded) {
        this.$plausible.trackEvent('inflection click', {props: {article: `${this.dictionary} ${this.article_id}`}})
      }
      this.inflection_expanded = !this.inflection_expanded
      
      
    }
  },
}

</script>

<style>
.show-inflection .v-icon {
  color: var(--v-primary-base) !important;
  margin-left: 4px !important;
}


@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

div.show-inflection {
  padding-top: 10px;
}

.inflection-canvas {
  overflow-x: auto;
  /*position: absolute;*/
  z-index: 5;
  background-color: rgba(255, 255, 255, 0);
  /*max-width: 100vw;*/

}

.inflection-canvas > div {
    padding-top: 10px;
    padding-bottom: 10px;
}


.inflection-wrapper {
  color: var(--v-text-base);
  font-size: 14px;
  margin-top: 10px;
}


.context {
  color: #545454 !important;
}

div.lemma {
  display: none;
}

table {
  border-collapse: collapse;
  margin-top: 5px;
}

th, td {
  border: solid 2px var(--v-button-darken1);
  padding: 5px;
}

th {
  background-color: var(--v-button-base);
}

.infl-label {
    text-align: center;
    vertical-align: top;
}


td.hilite {
    background-color: var(--v-tertiary-base);
    text-align: center
}

</style>
