<template>
  <section id="search_results" :class="$vuetify.breakpoint.name">
    
    <div v-if="show_per_page" class="pagination-container">
    <Pagination v-if="show_pagination" @update-page="$emit('update-page')"/>
    <span id="per-page">
           <span id="per-page-label">{{$t('per_page')}}</span>
                  <v-select     class="per-page-select"
                                aria-labelledby="per-page-label"
                                v-model="$parent.perPage" 
                                :items="[10, 20, 50, 100]" 
                                 @input="$parent.update_per_page"
                                hide-details
                                dense
                                :menu-props="{ offsetY: true }"
                                append-icon="expand_more"/>
        </span>
    </div>
    <div class="flex-container" v-if="!$parent.waiting" :class="$vuetify.breakpoint.name">

       <div class="hits" v-if="$vuetify.breakpoint.smAndDown">
        <div  class="article_container" v-for="(result, index) in results_bm.concat(results_nn)" :key="index + both_hash"
        :style="'order:'+(index < results_bm.length? 2*index : ((index-results_bm.length)*2) + 1)">
          <Article
              :article="result"
              :title_id="`result${index}`"
              :queryPattern="queryPattern"
              :scope="scope"
              @article-click="article_link_click"
              @details-click="details_click">
          </Article>
        </div>
      </div>

      <div class="hits" v-if="$vuetify.breakpoint.mdAndUp && lang!= 'nn'" v-bind:class="{'wide': lang=='bm'}">
                 <div class="dict-label-top"> <span role="heading" aria-level="2" class="dict-label-title"> bokmålsordboka </span><span v-if="count_bm != 1"> | <span v-if="count_bm == 0">{{$t('notifications.no_results')}}</span><span v-else>{{count_bm}} {{$t('notifications.results')}}</span></span></div>
        <div  class="article_container" v-for="(result, index) in results_bm" :key="index + bm_hash">
          <Article
              :article="result"
              :title_id="`result${index}`"
              :queryPattern="queryPattern"
              :scope="scope"
              @article-click="article_link_click"
              @details-click="details_click">
          </Article>
        </div>
      </div>
      <div class="hits" v-if="$vuetify.breakpoint.mdAndUp && lang!='bm'" v-bind:class="{'wide': lang=='nn'}">
        <div class="dict-label-top"> <span role="heading" aria-level="2" class="dict-label-title"> nynorskordboka </span><span v-if="count_nn != 1"> | <span v-if="count_nn == 0">{{$t('notifications.no_results')}}</span><span v-else>{{count_nn}} {{$t('notifications.results')}}</span></span></div>
        <div class="article_container" v-for="(result, index) in results_nn" :key="index + nn_hash">
          <Article
              :article="result"
              :title_id="`result${index + results_bm.length}`"
              :queryPattern="queryPattern"
              :scope="scope"
              @article-click="article_link_click"
              @details-click="details_click">
          </Article>
        </div>
      </div>
    </div>
    <Pagination v-if="show_pagination && !$parent.waiting" @update-page="$emit('update-page')" bottom/>

   <div>
  </div>
  </section>
  
</template>

<script>
import Article from './Article.vue'
import Pagination from './Pagination.vue'
export default {
  name: 'SearchResults',
  props: {
    results_bm: Array,
    results_nn: Array,
    lang: String,
    meta: Object,
    queryPattern: String,
    scope: String
  },
  computed: {
    both_hash: function(){
      return this.results_bm.concat(this.results_nn).reduce((hash, hit) => (hash + hit.article_id) % 10000, 0)
    },
    bm_hash: function(){
      return this.results_bm.reduce((hash, hit) => (hash + hit.article_id) % 10000, 0)
    },
    nn_hash: function(){
      return this.results_nn.reduce((hash, hit) => (hash + hit.article_id) % 10000, 0)
    },
    count_bm: function(){
      
      if (this.$parent.article_info.articles.bm ){
        return this.$parent.article_info.articles.bm.length
      }
      else {
        return 0
      }
    },
    count_nn: function(){
      if (this.$parent.article_info.articles.nn ){
        return this.$parent.article_info.articles.nn.length
      }
      else {
        return 0
      }
    },
  show_pagination: function() {
    return !this.$parent.article && this.$parent.article_info && (this.count_bm > this.$parent.perPage || this.count_nn > this.$parent.perPage)
    },

    show_per_page: function() {
    return this.$parent.article_info && (this.count_bm > 10 || this.count_nn > 10) && !this.$parent.article
  },
  },
  methods: {
    article_link_click: function(item) {
      this.$emit('article-click', item)
    },
    details_click: function(item) {
      this.$emit('details-click', item)
    },
    
  },
  components: {
    Article,
    Pagination
  }
}

</script>
<style>
  .article h3 {
    color: var(--v-primary-base);
    margin: 0px;
    padding: 20px 0px 20px 0px;
  }

  .flex-container {
    padding-top: 0px;
    display: flex;
  }

  .flex-container.sm, .flex-container.xs {
      padding-top: 0px;
  }

  .hits {
    min-width: 50%;
  }

  .hits.wide {
    min-width: 50%;
  }

  .flex-container.sm .hits, .flex-container.xs .hits {
    min-width: 100%;
    display: flex;
    flex-direction: column;
  }
 
  .results-count {
    color: var(--v-primary-base);
    margin-left: 24px;

  }

  .pagination {
    display: flex;
    flex-wrap: wrap;

  }
  .total-results {
    font-weight: bold;
    color: var(--v-primary-base);
  }

  .result-counts {
    padding: 10px;
    justify-content: right !important;
  }

  .total-results, .dict-counts {
    white-space: nowrap;
  }

  .pagination ul {
    padding-inline-start: 0px !important;
    justify-content: right;
  }

  #per-page {
    padding-bottom: 10px;
    padding-right: 10px;
    margin-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;
  
}



.per-page-select {
  max-width: 64px;;
}


.pagination-container {
  margin-left: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.lg .pagination-container, .xl .pagination-container {
    padding-top: 10px;

}

.dict-label-title {
  color: var(--v-primary-base)  ;
  font-weight: bold;
  font-size: 24px;
  font-variant-caps: all-small-caps;
}

.dict-label-top {
  padding: 10px;
  padding-left: 24px;
}

</style>
