import Vue from 'vue'
import Root from './Root.vue'
import App from './App.vue'
import About from './components/About.vue'
import DictionaryView from './components/DictionaryView.vue'
import VueRouter from 'vue-router'
import { VuePlausible } from 'vue-plausible'
import vuetify from './plugins/vuetify'
import Vuex from 'vuex'
import i18n from './i18n'
import VueMeta from 'vue-meta'


Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueRouter)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VuePlausible, {
  domain: 'ordbokene.no'
})

Vue.$plausible.enableAutoPageviews()



const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      component: App,
      beforeEnter(to, from , next) {
        if (to.query.OPP) { // Legacy
          let query = {q: to.query.OPP.toUpperCase() == to.query.OPP ? to.query.OPP.toLowerCase() : to.query.OPP, scope: "ei"}
          if (to.query.ava && to.query.type == "fritekst") {
            query["scope"] = "eif"
          }
          let lang = {"bokmaal": "/bm", "nynorsk": "/nn"}[to.query.ordbok] || "bm,nn"
          if (to.query.bokmaal && !to.query.nynorsk) {
            lang = "/bm"
          }
          if (to.query.nynorsk && !to.query.bokmaal) {
            lang = "/nn"
          }
          next({path: lang+"/search", query})
        }
        else {
          next()
        }
      },
      children: [
        {
          path: 'om',
          name: 'about',
          component: About
        },
        {
          path: "legacy/:query", // Legacy
          redirect: to => {
            console.log(to)
            let q = to.params.query.replace(/[\[\]]/g, "")
            if (q.toUpperCase() == q) {
              q = q.toLowerCase()
            }
            return {path: "bm,nn/search", query: {q}}
          }
        },
        {
          path: '',
          component: DictionaryView,
          children: [
            {
              path: ':lang(bm|nn|bm,nn)',
              children: [
                {
                  path: 'search',
                  name: 'search'
                },
                {
                  name: 'lookup',
                  path: ':id(\\d+)/:lemma?'
                },
                {
                  name: "word",
                  path: ':q([^?]+)'
                }
              ]
            }
          ]
        }
      ]
    }
  ]
})

// All interaction with local storage is encapsulated in vuex
const store = new Vuex.Store({
  strict: true,
  state: {
    showSearchToolbar: null,
    showInflectionNo: null,
    currentLocale: null,
    collapseArticles: 'never',
    defaultDict: null,
    perPage: 20,
    menuOpen: false,
    noMouse: null,
    searchRoute: null,
    fulltextHighlight: false,
    unavailable: null,
    localeChosen: false,
    inflectionExpanded: false,
    inflectionTableContext: true
  },
  mutations: {
    initStore(state) {
      try {
      state.showInflectionNo = localStorage.getItem('showInflectionNo') && localStorage.getItem('showInflectionNo') != 'false'
      state.currentLocale = localStorage.getItem('currentLocale') || null
      state.defaultDict = localStorage.getItem('defaultDict') || 'bm,nn'
      state.perPage = localStorage.getItem('perPage') || 20
      state.collapseArticles = localStorage.getItem('collapseArticles') || 'never'
      state.localeChosen = localStorage.getItem('currentLocale') ? true : false
      state.inflectionExpanded = localStorage.getItem('inflectionExpanded') && localStorage.getItem('inflectionExpanded') != 'false'
      state.inflectionTableContext = !localStorage.getItem('inflectionTableContext') || localStorage.getItem('inflectionTableContext') == 'true' ? true : false

      } catch(e) {
        console.log("localStorage unavailable")
        state.unavailable = true
      }

      if (!state.currentLocale) {
        let locales = navigator.languages.map(l => l.split("-")[0])
        if (locales.includes("nn")) state.currentLocale = "nno"
        else if (locales.includes("nb")) state.currentLocale = "nob"
        else {
          state.currentLocale = Math.random() < 0.5 ? "nob" : "nno";
          if (!state.unavailable) {
            localStorage.setItem('currentLocale', state.currentLocale)
          }
        }
      }
      i18n.locale = state.currentLocale
      state.noMouse = navigator.userAgentData? navigator.userAgentData.mobile : !window.matchMedia('(pointer: fine)').matches

    },
    setLocale(state, payload) {
      state.currentLocale = payload.value
      i18n.locale = payload.value
      state.localeChosen = true
      if (!state.unavailable) localStorage.setItem("currentLocale", payload.value);
      
    },
    setCollapse(state, value) {
      if (!state.unavailable) localStorage.setItem("collapseArticles", value)
      state.collapseArticles = value
    },
    setFulltextHighlight(state, value) {
      state.fulltextHighlight = value
    },
    setPerPage(state, value) {
      if (!state.unavailable) localStorage.setItem("perPage", value)
      state.perPage = value
    },
    toggleInflectionNo(state) {
      state.showInflectionNo = !state.showInflectionNo
      if (!state.unavailable) localStorage.setItem('showInflectionNo', state.showInflectionNo);
    },
    disableHotkey(state) {
      state.disableHotkey = !state.disableHotkey
      if (!state.unavailable) localStorage.setItem('disableHotkey', state.disableHotkey);
    },
    toggleInflectionTableContext(state) {
      state.inflectionTableContext = !state.inflectionTableContext
      if (!state.unavailable) localStorage.setItem('inflectionTableContext', state.inflectionTableContext);
    },
    toggleInflectionExpanded(state) {
      state.inflectionExpanded = !state.inflectionExpanded
      if (!state.unavailable) localStorage.setItem('inflectionExpanded', state.inflectionExpanded);
    },
    toggleMenu(state) {
      state.menuOpen = !state.menuOpen
    },
    setDefaultDict(state, value) {
      if (!state.unavailable) localStorage.setItem("defaultDict", value)
      state.defaultDict = value
    },
    resetStore() {
      localStorage.removeItem("showSearchToolbar")
      localStorage.removeItem("showInflectionNo")
      localStorage.removeItem("currentLocale")
      localStorage.removeItem("collapseArticles")
      localStorage.removeItem("defaultDict")
      localStorage.removeItem("perPage")
      localStorage.removeItem("inflectionTableContext")
      localStorage.removeItem("ukr_popup")
      localStorage.removeItem("test_info")
      localStorage.removeItem("beta_info")
      localStorage.removeItem("app_info")
      this.commit("initStore")
    },
    setSearchRoute(state, path) {
      state.searchRoute = path
    }
  }
})

new Vue({
  router,
  vuetify,
  store,

  beforeCreate() {
		this.$store.commit('initStore');
	},

  i18n,
  render: h => h(Root )
}).$mount('#app')
