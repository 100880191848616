<template>
<div>
        <v-app-bar class = "top-bar" dense elevation="0" dark color="primary">      
      <v-app-bar-title class="beta" :title="release" aria-label="Ordbøkene: Bokmålsordboka og Nynorskordboka"><a href ="/">{{$vuetify.breakpoint.smAndDown ? 'Ordbøkene' : 'ordbøkene.no'}}</a></v-app-bar-title>

      <v-spacer/>
      <v-divider vertical/>

      

      <v-toolbar-items>
        <v-divider vertical />
          <v-btn id="locale_button" 
             aria-haspopup="dialog"
             aria-label="Locale | Visningsspråk" 
             icon @click.native="chooseLocale = !chooseLocale">
          <v-icon>language</v-icon></v-btn>
         <v-divider vertical />
      <v-btn id="top_menu" 
             :aria-expanded="menuOpen? 'true' : 'false'"
             aria-haspopup="dialog"
             width="96px" 
             aria-labelledby="menu_label" 
             text @click.native="menuOpen = !menuOpen">
         <span id="menu_label">{{$t('menu.title')}}</span><v-icon right>{{menuOpen? 'close' : 'menu'}}</v-icon></v-btn>
        
        </v-toolbar-items>
        <v-divider vertical/>
        </v-app-bar>
                <v-dialog origin="48px center" content-class="dialogMenu" aria-labelledby="menu_label" max-width="1200" width="1200" v-model="menuOpen" hide-overlay
        :close-on-content-click="false" transition="dialog-top-transition">
        <Menu @close="menuOpen=false"/>
        </v-dialog>

      <v-dialog max-width="600px" v-model="chooseLocale">
        <v-card>
          <v-toolbar elevation="0" color="primary" text>
            <v-toolbar-title class="language-dialog-title">Locale | Visningsspråk</v-toolbar-title></v-toolbar>
          <v-card-text class="text--primary">
            <v-list three-line>
              <v-list-item link v-on:click="choose_locale('nob')">
                <v-list-item-content>
                  <v-list-item-title>Norsk (bokmål)</v-list-item-title>
                  <v-list-item-subtitle>Vis overskrifter, knapper og menyer på bokmål</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>  
              <v-list-item link v-on:click="choose_locale('nno')">
                <v-list-item-content>
                  <v-list-item-title>Norsk (nynorsk)</v-list-item-title>
                  <v-list-item-subtitle>Vis overskrifter, knappar og menyar på nynorsk</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
             <v-list-item link v-on:click="choose_locale('eng')">
                <v-list-item-content>
                  <v-list-item-title>English</v-list-item-title>
                  <v-list-item-subtitle>Choose English as display language for headings, buttons and menus</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link v-on:click="choose_locale('ukr')">
                <v-list-item-content>
                  <v-list-item-title>Українська (ukrainsk)</v-list-item-title>
                  <v-list-item-subtitle>Виберіть українську як мову відображення заголовків, кнопок і меню</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
        </div>
</template>

<script>
import Menu from './Menu.vue'

export default {
    name: "TopBar",
    data: function() {
      return {
        version_label: process.env.VUE_APP_VERSION_LABEL,
        release: process.env.VUE_APP_RELEASE,
        chooseLocale: false
      }
    },
    computed: {
      menuOpen: {
        get() {
          return this.$store.state.menuOpen
        },
        set () {
          this.$store.commit('toggleMenu')
        }
      }
    },
    methods: {
      home: function() {
        this.$router.push('/')
      },
      choose_locale: function(locale) {
        this.$store.commit("setLocale", {value: locale, i18n: this._i18n})
        this.chooseLocale = false
      }

    },
    components: {
        Menu
    },
}
</script>

<style >

.dialogMenu {
  margin: 0px !important;
  align-self: flex-start !important;
  margin-top: 48px !important;
}


.v-app-bar-title a {
  text-decoration: none;
  color: white !important;
}





 
</style>