import Vue from 'vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 470,
      sm: 700,
      md: 1250,
      lg: 1900,
    },
    scrollBarWidth: 24,
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#560027',
        secondary: '#BC477B',
        tertiary: '#FDF4F5',
        anchor: '#880E4F',
        error: '#FDF4F5',
        border: '#9E9E9E',
        button: '#f5f5f5',
        text: '#000000'
      }
    }
   },
  defaultAssets: {
    font: true,
    icons: 'md'
  },
  icons: {
    iconfont: 'md',
}
});
